import { initializeApp } from 'firebase/app';
import Modal from './components/Modal';
import { useEffect, useMemo, useState, useCallback } from 'react';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import Button from './components/Button';
import Input from './components/Input';
import toast, { Toaster } from 'react-hot-toast';
import { IEvent, IPayerInfo } from './interface';
import {
  getFirestore,
  query,
  doc,
  getDoc,
  collection,
  where,
  getDocs,
  setDoc,
  addDoc,
} from 'firebase/firestore';
import dayjs from 'dayjs';
import { TrashIcon, CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { formatCurrency } from './helpers';
dayjs.locale('zh-cn');

const loginFormInit = {
  email: '',
  password: '',
};

const initUserData = {
  nickname: '',
  realname: '',
};

const initTab = [
  { name: '未结算的活动', current: true },
  { name: '已结算的活动', current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const createEventInit = {
  title: '',
  desc: '',
  createAt: new Date(),
  payers: [] as IPayerInfo[],
};

function App() {
  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: 'AIzaSyClCCDUGonpJy1Pl_QWdX0c31BwECyfUAc',
    authDomain: 'aa-pay.firebaseapp.com',
    projectId: 'aa-pay',
    storageBucket: 'aa-pay.appspot.com',
    messagingSenderId: '460972440771',
    appId: '1:460972440771:web:dd410577ec02366a03f676',
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);

  const [uid, setUid] = useState<string | null>(null);
  const [allUsers, setAllUsers] = useState(
    [] as { uid: string; name: string }[]
  );
  const [loginForm, setLoginForm] = useState(loginFormInit);
  const [openLogin, setOpenLogin] = useState(false);
  const [openCreateEvent, setOpenCreateEvent] = useState(false);
  const [openEventDetail, setOpenEventDetail] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [userData, setUserData] = useState(initUserData);
  const [myEventTab, setMyEventTab] = useState(initTab);
  const [myUnpaidEvents, setMyUnpaidEvents] = useState([] as IEvent[]);
  const [myEndEvents, setMyEndEvents] = useState([] as IEvent[]);
  const [createEventForm, setCreateEventForm] = useState(createEventInit);
  const [isAllAmountSame, setIsAllAmountSame] = useState(false);
  const [waitMeToPayEvents, setWaitMeToPayEvents] = useState([] as IEvent[]);
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
        setOpenLogin(false);
        setLoginForm(loginFormInit);
      } else {
        setUid(null);
        setOpenLogin(true);
      }
    });
  }, [auth]);

  const fetchUserData = useCallback(async () => {
    try {
      if (!uid) {
        return;
      }
      const docRef = doc(db, 'Users', uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserData(docSnap.data() as any);
      } else {
        throw new Error('用户信息不存在');
      }
    } catch (err) {
      toast.error('获取用户信息失败');
    }
  }, [db, uid]);

  const fetchAllUsers = useCallback(async () => {
    try {
      const userRef = collection(db, 'Users');
      const querySnapshot = await getDocs(userRef);
      const tempUsers = [] as any[];
      querySnapshot.forEach((doc) => {
        tempUsers.push({
          uid: doc.id,
          name: doc.data().nickname || doc.data().realname,
        });
      });
      setAllUsers(tempUsers);
    } catch (err) {
      toast.error('获取用户信息失败');
    }
  }, [db]);

  const fetchWaitMeToPayEvents = useCallback(async () => {
    const eventRef = collection(db, 'Events');
    const q2 = query(eventRef, where('payerIds', 'array-contains', uid));
    const querySnapshot2 = await getDocs(q2);
    const tempWaitMeToPayEvents = [] as IEvent[];
    querySnapshot2.forEach((doc) => {
      if (
        !!doc
          .data()
          .payers.find((p: IPayerInfo) => p.userId === uid && p.paid === false)
      ) {
        tempWaitMeToPayEvents.push(doc.data() as IEvent);
      }
    });
    setWaitMeToPayEvents(tempWaitMeToPayEvents);
  }, [db, uid]);

  const fetchUserEvents = useCallback(async () => {
    try {
      if (!uid) {
        return;
      }
      //fetch events that I created
      const eventRef = collection(db, 'Events');
      const q = query(eventRef, where('requester', '==', uid));
      const querySnapshot = await getDocs(q);
      const tempEndEvents = [] as IEvent[];
      const tempUnpaidEvents = [] as IEvent[];
      querySnapshot.forEach((doc) => {
        const _ = doc.data() as IEvent;
        _.id = doc.id;
        if (_.payers.length === 0 || !_.payers.find((p) => p.paid === false)) {
          tempEndEvents.push(_);
        } else {
          tempUnpaidEvents.push(_);
        }
      });
      setMyUnpaidEvents(tempUnpaidEvents);
      setMyEndEvents(tempEndEvents);
      await fetchWaitMeToPayEvents();
    } catch (err) {
      toast.error('获取用户活动失败');
    }
  }, [db, uid, fetchWaitMeToPayEvents]);

  useEffect(() => {
    fetchUserData();
    fetchUserEvents();
  }, [uid, fetchUserData, fetchUserEvents]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  const handleLogin = async () => {
    try {
      setLoginLoading(true);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        loginForm.email,
        loginForm.password
      );
      if (userCredential.user) {
        setLoginLoading(false);
        toast.success('登陆成功');
      } else {
        throw new Error('登陆失败');
      }
    } catch (err) {
      setLoginLoading(false);
      toast.error('登陆失败');
    }
  };

  const handleLogout = () => {
    auth.signOut();
  };

  function displayWelcomeMessage(): string {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    let message = 'Hello!';

    if (currentHour >= 0 && currentHour < 6) {
      message = '凌晨好';
    } else if (currentHour >= 6 && currentHour < 12) {
      message = '早上好';
    } else if (currentHour >= 12 && currentHour < 17) {
      message = '下午好';
    } else if (currentHour >= 17 && currentHour < 21) {
      message = '傍晚好';
    } else if (currentHour >= 21 && currentHour <= 23) {
      message = '晚上好';
    }
    return message;
  }

  const handleCreateEvent = async () => {
    try {
      if (!uid) {
        throw new Error('用户信息不存在');
      }
      //make sure title and desc are not empty
      if (createEventForm.title === '') {
        throw new Error('标题不能为空');
      }
      //make sure at least one payer
      if (createEventForm.payers.length === 0) {
        throw new Error('至少需要一个参与者');
      }
      //make sure all amount are positive
      if (
        createEventForm.payers.find((payer) => payer.amount <= 0) !== undefined
      ) {
        throw new Error('金额必须大于0');
      }

      const newEvent = {
        ...createEventForm,
        requester: uid,
        payers: createEventForm.payers.map((payer) => ({
          ...payer,
          paid: false,
        })),
        payerIds: createEventForm.payers.map((payer) => payer.userId),
      };
      const df = await addDoc(collection(db, 'Events'), newEvent);
      setMyUnpaidEvents([
        ...myUnpaidEvents,
        {
          ...newEvent,
          createAt: { seconds: dayjs(new Date(newEvent.createAt)).unix() },
          id: df.id,
        },
      ]);
      toast.success('创建活动成功');
      setOpenCreateEvent(false);
      setCreateEventForm(createEventInit);
    } catch (err) {
      toast.error('创建活动失败');
    }
  };

  const handleMarkAsPaid = async (userId: string) => {
    try {
      if (!selectedEvent) {
        throw new Error('活动不存在');
      }
      const eventRef = doc(db, 'Events', selectedEvent.id);
      await setDoc(
        eventRef,
        {
          ...selectedEvent,
          payers: selectedEvent.payers.map((payer) =>
            payer.userId === userId ? { ...payer, paid: true } : payer
          ),
        },
        { merge: true }
      );
      toast.success('成功');
      setSelectedEvent({
        ...selectedEvent,
        payers: selectedEvent.payers.map((payer) =>
          payer.userId === userId ? { ...payer, paid: true } : payer
        ),
      });
      await fetchUserEvents();
    } catch (err) {
      toast.error('失败');
    }
  };

  const handleMarkAsUnpaid = async (userId: string) => {
    try {
      if (!selectedEvent) {
        throw new Error('活动不存在');
      }
      const eventRef = doc(db, 'Events', selectedEvent.id);
      await setDoc(
        eventRef,
        {
          ...selectedEvent,
          payers: selectedEvent.payers.map((payer) =>
            payer.userId === userId ? { ...payer, paid: false } : payer
          ),
        },
        { merge: true }
      );
      toast.success('成功');
      setSelectedEvent({
        ...selectedEvent,
        payers: selectedEvent.payers.map((payer) =>
          payer.userId === userId ? { ...payer, paid: false } : payer
        ),
      });
      await fetchUserEvents();
    } catch (err) {
      toast.error('失败');
    }
  };

  const showingEvents = useMemo(
    () =>
      !!myEventTab.find((t) => t.current === true && t.name === '未结算的活动')
        ? myUnpaidEvents
        : myEndEvents,
    [myEventTab, myUnpaidEvents, myEndEvents]
  );

  return (
    <>
      {' '}
      <Toaster />
      <div className=''>
        {/* login modal */}
        <Modal open={openLogin} setOpen={setOpenLogin}>
          <div className='flex min-h-full flex-1 flex-col justify-center px-6 pb-6 lg:px-8'>
            <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
              <img
                className='mx-auto h-40 w-auto'
                src='https://i.stack.imgur.com/022Fl.gif'
                alt='logo'
              />
              <h2 className='mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
                速速登陆
              </h2>
            </div>

            <div className='mt-4 sm:mx-auto sm:w-full sm:max-w-sm space-y-4'>
              <Input
                disabled={loginLoading}
                type='email'
                label={'邮箱'}
                placeholder={'你的邮箱'}
                value={loginForm.email}
                onChange={(value: string) => {
                  setLoginForm({
                    ...loginForm,
                    email: value,
                  });
                }}
              />
              <Input
                disabled={loginLoading}
                type='password'
                label={'密码'}
                placeholder={'默认：123456'}
                value={loginForm.password}
                onChange={(value: string) => {
                  setLoginForm({
                    ...loginForm,
                    password: value,
                  });
                }}
              />
              <div className='flex justify-center'>
                <Button text='登陆' onClick={() => handleLogin()} />
              </div>
            </div>
          </div>
        </Modal>
        {/* create event modal */}
        <Modal open={openCreateEvent} setOpen={setOpenCreateEvent}>
          <div className='space-y-12'>
            <div className='border-b border-gray-900/10 pb-12'>
              <h2 className='text-base font-semibold leading-7 text-gray-900'>
                创建新的活动
              </h2>
              <p className='mt-1 text-sm leading-6 text-gray-600'>
                请填写活动的基本信息。
              </p>

              <div className='sm:col-span-4 mt-6'>
                <Input
                  label={'活动标题'}
                  placeholder={'请填写活动标题'}
                  value={createEventForm.title}
                  onChange={(value: string) => {
                    setCreateEventForm({
                      ...createEventForm,
                      title: value,
                    });
                  }}
                />
              </div>
              <div className='sm:col-span-4 mt-4'>
                <Input
                  label={'活动描述'}
                  placeholder={'请简单描述一下这个活动'}
                  value={createEventForm.desc}
                  onChange={(value: string) => {
                    setCreateEventForm({
                      ...createEventForm,
                      desc: value,
                    });
                  }}
                />
              </div>
              <div className='sm:col-span-4 mt-4'>
                <Input
                  label={'活动时间'}
                  placeholder={'活动在哪天进行的呢'}
                  value={createEventForm.createAt.toISOString().split('T')[0]}
                  onChange={(value: string) => {
                    setCreateEventForm({
                      ...createEventForm,
                      createAt: new Date(value),
                    });
                  }}
                  type='date'
                />
              </div>
              <label className='relative inline-flex items-center cursor-pointer mt-4'>
                <input
                  type='checkbox'
                  checked={isAllAmountSame}
                  onChange={() => setIsAllAmountSame(!isAllAmountSame)}
                  className='sr-only peer'
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                <span className='ml-3 text-sm font-medium text-gray-900 dark:text-gray-300'>
                  所有人付相同的金额
                </span>
              </label>
              {createEventForm.payers.map((payer, idx) => (
                <div key={idx} className='flex mt-2'>
                  <div className='flex-1 mr-2'>
                    <div>
                      <label
                        htmlFor='location'
                        className='block text-sm font-medium leading-6 text-gray-900'
                      >
                        参与者
                      </label>
                      <select
                        id='location'
                        name='location'
                        className='mt-2 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-teal-600 sm:text-sm sm:leading-6'
                        value={payer.userId}
                        onChange={(e) => {
                          const tempPayers = [...createEventForm.payers];
                          const selectedIndex = e.target.options.selectedIndex;
                          tempPayers[idx].userId = allUsers[selectedIndex].uid;
                          tempPayers[idx].userName =
                            allUsers[selectedIndex].name;
                          setCreateEventForm({
                            ...createEventForm,
                            payers: tempPayers,
                          });
                        }}
                      >
                        {allUsers.map((user, index) => (
                          <option key={index} value={user.uid}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='flex-1'>
                    <label
                      htmlFor='email'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      金额（$）
                    </label>
                    <div className='mt-2'>
                      <input
                        value={createEventForm.payers[idx].amount}
                        onChange={(e) => {
                          const tempPayers = [...createEventForm.payers];
                          if (isAllAmountSame) {
                            tempPayers.forEach((payer) => {
                              payer.amount = Number(e.target.value);
                            });
                          } else {
                            tempPayers[idx].amount = Number(e.target.value);
                          }

                          setCreateEventForm({
                            ...createEventForm,
                            payers: tempPayers,
                          });
                        }}
                        id='desc'
                        name='desc'
                        type='number'
                        className='px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6'
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      const tempPayers = [...createEventForm.payers];
                      tempPayers.splice(idx, 1);
                      setCreateEventForm({
                        ...createEventForm,
                        payers: tempPayers,
                      });
                    }}
                    className='flex self-end ml-4'
                  >
                    <TrashIcon className='w-8 h-8 text-red-400 cursor-pointer' />
                  </button>
                </div>
              ))}
              <button
                onClick={() => {
                  setCreateEventForm({
                    ...createEventForm,
                    payers: [
                      ...createEventForm.payers,
                      {
                        userId: allUsers[0].uid,
                        userName: allUsers[0].name,
                        paid: false,
                        amount: 0,
                      },
                    ],
                  });
                }}
                className='border border-dashed border-teal-400 w-full p-2 text-center rounded-md mt-2 text-teal-500 hover:text-teal-700'
              >
                添加更多参与者
              </button>
            </div>
          </div>

          <div className='mt-6 flex items-center justify-end gap-x-6'>
            <button
              type='button'
              onClick={() => {
                setOpenCreateEvent(false);
                setCreateEventForm(createEventInit);
              }}
              className='text-sm font-semibold leading-6 text-gray-900'
            >
              取消
            </button>
            <button
              onClick={() => handleCreateEvent()}
              className='rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600'
            >
              创建
            </button>
          </div>
        </Modal>
        <Modal open={openEventDetail} setOpen={setOpenEventDetail}>
          {selectedEvent && (
            <div className='space-y-12'>
              <div className=''>
                <div className='flex justify-between'>
                  <h2 className='text-xl font-semibold leading-7 text-gray-900'>
                    {selectedEvent.title}
                  </h2>
                  <p className='text-gray-400'>
                    {dayjs(
                      new Date(selectedEvent.createAt.seconds * 1000)
                    ).format('YYYY-MM-DD')}
                  </p>
                </div>

                <p className='mt-1 text-sm leading-6 text-gray-600'>
                  {selectedEvent.desc}
                </p>
              </div>
              <div className=''>
                <h2 className='text-base font-semibold leading-7 text-gray-900'>
                  参与者
                </h2>
                <div className='mt-1'>
                  {selectedEvent.payers.map((payer, idx) => (
                    <div
                      className='flex justify-between w-full items-center'
                      key={idx}
                    >
                      <p className='font-semibold space-x-2'>
                        <span
                          className={
                            payer.paid ? 'text-green-500' : 'text-red-500'
                          }
                        >
                          {payer.userName}
                        </span>
                        <span>{formatCurrency(payer.amount)}</span>
                      </p>
                      {payer.paid ? (
                        <button
                          onClick={() => handleMarkAsUnpaid(payer.userId)}
                          className='px-2 py-1 bg-red-500 rounded-md'
                        >
                          <XMarkIcon className='h-6 w-6 text-white' />
                        </button>
                      ) : (
                        <button
                          onClick={() => handleMarkAsPaid(payer.userId)}
                          className='px-2 py-1 bg-green-500 rounded-md flex text-white gap-2'
                        >
                          <CheckIcon className='h-6 w-6 ' />
                          <span>标记为已付款</span>
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Modal>
        {uid ? (
          <main>
            <header className='relative isolate pt-0'>
              <div
                className='absolute inset-0 -z-10 overflow-hidden'
                aria-hidden='true'
              >
                <div className='absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80'>
                  <div
                    className='aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#34ebba] to-[#b5e8da]'
                    style={{
                      clipPath:
                        'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                    }}
                  />
                </div>
                <div className='absolute inset-x-0 bottom-0 h-px bg-gray-900/5' />
              </div>

              <div className='mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8'>
                <div className='mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none'>
                  <div className='flex items-center gap-x-2'>
                    <img
                      src='https://i.pinimg.com/originals/03/1d/1c/031d1c30843683ff843a9fd52b5b5796.png'
                      alt=''
                      className='h-14 flex-none'
                    />
                    <h1>
                      <div className='text-sm leading-6 text-gray-500'>
                        欢迎回来
                      </div>
                      <div className='font-mono mt-1 text-xl font-semibold leading-6 text-gray-900'>
                        {userData.nickname}
                      </div>
                    </h1>
                  </div>
                  <div className='flex items-center gap-x-4 sm:gap-x-6'>
                    <Button text='登出' onClick={() => handleLogout()} />
                  </div>
                </div>
              </div>
            </header>

            <div className='mx-auto max-w-2xl lg:max-w-7xl px-4 py-8 sm:px-0 lg:px-8'>
              <div className='mx-auto lg:mx-0 bg-white py-4'>
                {/* My event header */}
                <div>
                  <div className='flex justify-between'>
                    <h2 className='mr-3 text-2xl font-bold tracking-tight text-gray-900'>
                      我创建的活动
                    </h2>
                    <Button
                      onClick={() => setOpenCreateEvent(true)}
                      text='创建活动'
                    />
                  </div>
                  <p className='my-2 text-md text-gray-400'>
                    包含了你的所有已经结束或者没结束的活动。
                  </p>
                </div>

                {/* My event tabs */}
                <div className='mx-auto'>
                  <div className='sm:hidden'>
                    <label htmlFor='tabs' className='sr-only'>
                      Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                      onChange={(e) => {
                        setMyEventTab(
                          myEventTab.map((t) =>
                            t.name === e.target.value
                              ? { ...t, current: true }
                              : { ...t, current: false }
                          )
                        );
                      }}
                      id='tabs'
                      name='tabs'
                      className='block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm'
                      value={myEventTab.find((tab) => tab.current)?.name}
                    >
                      {myEventTab.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className='hidden sm:block'>
                    <div className='border-b border-gray-200'>
                      <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
                        {myEventTab.map((tab) => (
                          <div
                            onClick={() =>
                              setMyEventTab(
                                myEventTab.map((t) =>
                                  t.name === tab.name
                                    ? { ...t, current: true }
                                    : { ...t, current: false }
                                )
                              )
                            }
                            key={tab.name}
                            className={classNames(
                              tab.current
                                ? 'border-teal-500 text-teal-600'
                                : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                              'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                          >
                            {tab.name}
                          </div>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>

                {/* My event cards */}
                <div className='mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                  {showingEvents.length > 0 ? (
                    showingEvents.map((event: IEvent, idx) => (
                      <div
                        key={idx}
                        className='col-span-1 w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'
                      >
                        <div>
                          <h5 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white'>
                            {event.title}
                          </h5>
                        </div>
                        <p className='mb-3 font-normal text-gray-700 dark:text-gray-400 truncate'>
                          {event.desc}
                        </p>
                        <div className='mb-4'>
                          {event.payers.map((payer, idx) => (
                            <span
                              key={idx}
                              className={
                                'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-gray-600 ' +
                                (payer.paid ? 'bg-teal-100 ' : 'bg-gray-100 ')
                              }
                            >
                              {payer.userName}
                            </span>
                          ))}
                        </div>
                        <div className='flex justify-between items-center'>
                          <Button
                            onClick={() => {
                              setSelectedEvent(event);
                              setOpenEventDetail(true);
                            }}
                            text='查看详情'
                          />
                          <p className='text-gray-400 text-sm'>
                            {event.createAt?.seconds
                              ? dayjs(
                                  new Date(event.createAt?.seconds * 1000)
                                ).format('YYYY-MM-DD')
                              : ''}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='col-span-1 w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                      <div className='flex justify-center items-center h-40'>
                        <p className='text-2xl font-bold text-gray-400'>
                          暂无活动
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {/* Events that wait for me to pay */}
                <div>
                  <div className='flex justify-between mt-10'>
                    <h2 className='mr-3 text-2xl font-bold tracking-tight text-gray-900'>
                      等待我付款的活动
                    </h2>
                    <Button
                      onClick={() => fetchWaitMeToPayEvents()}
                      text='刷新'
                    />
                  </div>
                  <p className='my-2 text-md text-gray-400'>
                    以下是所有等待你付款的活动。
                  </p>
                </div>
                <div className='mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                  {waitMeToPayEvents.length > 0 ? (
                    waitMeToPayEvents.map((event: IEvent, idx) => (
                      <div
                        key={idx}
                        className='col-span-1 w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'
                      >
                        <div className='flex justify-between'>
                          <h5 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white'>
                            {event.title}
                          </h5>
                          <p className='text-gray-400 text-sm'>
                            {dayjs(
                              new Date(event.createAt.seconds * 1000)
                            ).format('YYYY-MM-DD')}
                          </p>
                        </div>
                        <p className='mb-3 font-normal text-gray-700 dark:text-gray-400 truncate'>
                          {event.desc}
                        </p>
                        <p className='text-semibold text-2xl text-teal-600'>
                          {formatCurrency(
                            event.payers.find(
                              (p) => p.userId === uid && p.paid === false
                            )?.amount || 0
                          )}
                        </p>
                      </div>
                    ))
                  ) : (
                    <div className='col-span-1 w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                      <div className='flex justify-center items-center h-40'>
                        <p className='text-2xl font-bold text-gray-400'>
                          暂无活动
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </main>
        ) : (
          <div className='w-screen h-screen flex flex-col justify-center items-center'>
            {/* display welcome message base on date and time */}
            <p className='mb-4 text-teal-500 font-semibold text-2xl'>
              {displayWelcomeMessage()}
            </p>
            <Button
              text='登陆'
              loading={loginLoading}
              onClick={() => setOpenLogin(true)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default App;
